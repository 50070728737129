.root :global .slick-slide {
  @apply text-center;
}

.root :global ul.slick-dots {
  & > li {
    @apply w-2 h-2;

    &:not(:first-child) {
      @apply ml-3.5;
    }

    button {
      @apply w-2 h-2 bg-tertiary-medium opacity-30 rounded-full p-0;
      transition: 0.5s;

      &:before {
        @apply hidden;
      }
    }
  }

  .slick-active {
    @apply w-6;

    button {
      @apply w-6 h-2 bg-tertiary-medium opacity-100;
    }
  }
}

.root :global ol.slick-dots {
  bottom: 10px!important;
  position: relative;
    @media(min-width: 1024px){
      position: absolute;
    }
  
  & > li {
    @apply w-2 h-2;

    &:not(:first-child) {
      @apply ml-3.5;
    }

    button {
      @apply w-2 h-2 bg-tertiary-medium opacity-30 rounded-full p-0;
      transition: 0.5s;

      &:before {
        @apply hidden;
      }
    }
  }

  .slick-active {
    @apply w-6;

    button {
      @apply w-6 h-2 bg-tertiary-medium opacity-100;
    }
  }
}
