.htmlBlock :global(li) {
  &:not(.trim) {
    margin-bottom: 0.3rem;
  }
}

.htmlblock :global(span) {
  &:empty {
    display: none;
  }
}

.htmlBlock :global(p) {
  &:not(.trim) {
    margin: 1rem 0;
  }
}

.htmlBlock :global(ul) {
  list-style: disc inside !important;
}

.htmlBlock :global(p):empty {
  display: none;
}

.htmlBlock :global(table) {
  border-collapse: separate;
  border-spacing: 0px;

  td {
    @apply border border-gray-light p-3.5;

    p {
      margin: 0 !important;
    }
  }

  tr:first-child {
    td:first-child {
      @apply rounded-tl-md;
    }
    td:last-child {
      @apply rounded-tr-md;
    }
  }
  tr:last-child {
    td:first-child {
      @apply rounded-bl-md;
    }
    td:last-child {
      @apply rounded-br-md;
    }
  }
}
