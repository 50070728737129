.root {
  --content-width: 22.5rem;

  height: 0;
  overflow: visible;
  left: -100vw;
  opacity: 0;
  position: absolute;
  top: 100%;
  transition: opacity 192ms var(--venia-global-anim-out),
  visibility 192ms var(--venia-global-anim-out),
    /* Adding delay to move container off the screen after previous transitions happened */
  left 0s 192ms;
  visibility: hidden;
  width: calc(100vw - 2rem);
  /* max-width wider than header to let dropdown to move left on wider screens while still staying near the trigger */
  max-width: calc(
          var(--venia-global-maxWidth) + var(--content-width) * 2 - 8rem
  );
  z-index: 3;
  transform: translate3d(-50%, 0, 0);
}

.root_open {
  composes: root;
  opacity: 1;
  transition: opacity 224ms var(--venia-global-anim-in),
  visibility 224ms var(--venia-global-anim-in), left 0s;
  visibility: visible;

  @apply fixed top-0 right-0 left-auto w-0 h-full z-50;
}

.contents {
  background-color: rgb(var(--venia-global-color-background));
  border-radius: 1px;
  box-shadow: 0 1px 6px rgb(var(--venia-global-color-gray-900) / 0.2);
  /* display: grid; */
  /* These heights may change based on PWA-605 and should be based on 1.5 and 2.5 visible items. */
  min-height: 20rem;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate3d(0, 0, 0);
  transition-duration: 192ms;
  transition-property: transform;
  transition-timing-function: var(--venia-global-anim-out);
  width: 21rem;

  @apply flex flex-col h-screen rounded-tl rounded-bl;
}

.contents_open {
  composes: contents;

  /* transform: translate3d(0, 4px, 0); */
  transition-duration: 224ms;
  transition-timing-function: var(--venia-global-anim-in);
}

.header {
  align-items: center;
  border-bottom: 2px solid rgb(var(--venia-global-color-gray-100));
  display: grid;
  font-size: var(--venia-global-typography-heading-XS-fontSize);
  font-weight: 600;
  grid-template-columns: 1fr auto;
  line-height: var(--venia-global-typography-heading-lineHeight);
  margin: 0 1rem;
  padding: 1rem 0.5rem 0.5rem;
  row-gap: 1rem;

  @apply m-0 p-4 bg-primary-medium text-white border-none;
}

.stockStatusMessageContainer {
  grid-column-end: span 2;
}

.stockStatusMessageContainer:empty {
  display: none;
}

.body {
  height: 100%;
  overflow: hidden;
  padding-bottom: 2rem;
}

.title {
  opacity: 1;
  transition-delay: 64ms;
  transition-duration: 384ms;
  transition-property: opacity;
  transition-timing-function: var(--venia-global-anim-standard);

  @apply text-lg font-bold;
}

.quantity_loading {
  composes: title;
  opacity: 0.5;
}

.price {
  opacity: 1;
  transition-delay: 64ms;
  transition-duration: 384ms;
  transition-property: opacity;
  transition-timing-function: var(--venia-global-anim-standard);
}

.price_loading {
  composes: title;
  opacity: 0.5;
}

.footer {
  align-items: center;
  border-top: 2px solid rgb(var(--venia-global-color-gray));
  display: grid;
  gap: 0.5rem;
  font-size: var(--venia-typography-body-S-fontSize);
  justify-items: center;
  line-height: var(--venia-typography-body-lineHeight);
  margin: 0 1rem;
  min-height: 3rem;
  overflow: hidden;
  padding: 1rem;
}

.checkoutButton {
  grid-column: 1 / span 1;
  width: 10rem;
  margin: auto;
  background-color: rgb(var(--venia-brand-color-1-700));
  border: none;
  font-weight: bold;
}

.editCartButton {
  text-decoration: underline;
  font-size: var(--venia-global-fontSize-100);
}

.emptyCart {
  @apply flex-1 p-0 flex flex-col justify-center items-center;
}

/* Custom classes */

.signature_area {
  background-color: #004073;
  border: 1px solid #dedede;
  border-top: none;
  border-radius: 4px 4px 4px 4px;
  margin: 8px 11px;
  color: #fefefe;
}

.signature_area p {
  font-size: 14px;
}

.signature_content {
  padding: 16px 11px 11px 12px;
}

.signature_bold_text {
  font-weight: bold;
}

.signature_content strong {
  margin-left: 10px;
}

.signature_header {
  display: flex;
  flex-direction: row;
}

.signature_icon {
  border-radius: 4px;
  padding: 4px;
  margin-right: 16px;
}
