.root {
  display: grid;
  grid-auto-flow: row;
  column-gap: 1rem;
  row-gap: 0.5rem;
  font-size: var(--venia-global-fontSize-100);
  opacity: 1;
  transition-delay: 64ms;
  transition-duration: 384ms;
  transition-property: opacity;
  transition-timing-function: var(--venia-global-anim-standard);
}
