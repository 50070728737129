@font-face {
  font-family: 'DisplayOTF';
  font-display: swap;
  font-style: normal;
  font-weight: normal;
  src:
    url('/assets/fonts/DisplayOTF.otf') format('opentype'),
    local('DisplayOTF');
}

div.clockDigit {
  font-family: 'DisplayOTF';
  color: #ea0f18;
}

.clockColon {
  font-family: 'DisplayOTF';
  font-size: 48px;
  color: #ea0f18;
}

.clockColonFlashSale {
  font-family: 'DisplayOTF';
  font-size: 2.5rem;
  color: #ea0f18;
}

.timerIcon {
  margin-top: -2px;
  g {
    color: transparent !important;
  }
  path {
    color: transparent !important;
  }
}
