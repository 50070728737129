.selectWrapper :global {
  .select {
    &__control {
      @apply border-gray-medium rounded-md cursor-pointer;
    }
    &__value-container {
      @apply py-3 pl-3;
    }
    &__input-container {
      @apply p-0 m-0;
    }
    &__indicator-separator {
      @apply hidden;
    }

    &__dropdown-indicator {
      @apply text-gray-dark pr-4;
    }
    &__single-value {
      @apply text-gray-dark;
    }
    &__option {
      @apply cursor-pointer;
    }
  }
}
