.root h1,
.root h2,
.root h3,
.root h4,
.root h5,
.root h6 {
  word-break: break-word;
  line-height: 1.25em;
  margin-bottom: 0.5em;
  margin-top: 1.85rem;
}

.root h1 {
  font-size: 2rem;
  margin-top: 0.9em;
  font-weight: 300;
}

.root h2 {
  font-size: 1.625rem;
  font-weight: 400;
}

.root h3 {
  font-size: 1.375rem;
  font-weight: 400;
}

.root h4 {
  font-size: 1.125rem;
  font-weight: 600;
}

.root h5 {
  font-size: 1rem;
  font-weight: 600;
}

.root h6 {
  font-size: 0.875rem;
  font-weight: 600;
}

.root p {
  margin-bottom: 1rem;
  line-height: 1.65em;
}

.root img {
  max-width: 100%;
  margin: 0;
}

.root ol,
.root ul {
  margin-block-start: 1.2em;
  margin-block-end: 1.2em;
  padding-left: 2em;
}

.root p + ol,
.root p + ul {
  margin-block-start: 0.2em;
}

.root ol {
  list-style-type: decimal;
}

.root ul {
  list-style-type: disc;
}

.root ol li,
.root ul li {
  line-height: 1.65em;
  padding: 1px 0.5rem;
}

.root a {
  text-decoration: underline;
}

.root blockquote {
  font-size: 1.375rem;
  line-height: 1.875rem;
  margin: 1rem 0;
  font-weight: 300;
  font-style: italic;
}

.root table {
  width: 100%;
  margin: 1rem 0;
  border-spacing: 0;
  border-left: 1px solid rgb(var(--venia-global-color-border));
  border-top: 1px solid rgb(var(--venia-global-color-border));
}

.root table td,
.root table th {
  text-align: left;
  padding: 1rem;
  font-size: 0.875rem;
  line-height: 1.125em;
  border-right: 1px solid rgb(var(--venia-global-color-border));
  border-bottom: 1px solid rgb(var(--venia-global-color-border));
}

.root table th {
  background-color: rgb(var(--venia-global-color-gray));
}

.root :global .cms-content-important {
  background: rgb(var(--venia-global-color-gray));
  padding: 1.2rem 1rem;
  font-size: 1.125rem;
  border: 1px solid rgb(var(--venia-global-color-border));
}

.root pre {
  background: rgb(var(--venia-global-color-gray));
  padding: 1rem;
  border: 1px solid rgb(var(--venia-global-color-border));
}

@media only screen and (min-width: 769px) {
  .root h1 {
    font-size: 3rem;
    margin-top: 0.75em;
    line-height: 1.05em;
  }
  .root h2 {
    font-size: 2.25rem;
    line-height: 1.25em;
  }
  .root h3 {
    font-size: 1.75rem;
    line-height: 1.25em;
  }
  .root h4 {
    font-size: 1.375rem;
    line-height: 1.25em;
    font-weight: 400;
  }
  .root h5 {
    font-size: 1.125rem;
    line-height: 1.22em;
    margin-bottom: 0.75em;
  }
  .root h6 {
    font-size: 1rem;
    line-height: 1.22em;
    margin-bottom: 0.75em;
  }
  .root p {
    margin-bottom: 1rem;
    line-height: 1.5em;
  }
}

/* Hack needed since tailwind margin doesn't work with some cms elements */
.root :global .mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.root :global * {
  --tw-text-opacity: 1;
  color: rgba(116, 116, 116, var(--tw-text-opacity));
}

.root :global(.pagebuilder-mobile-hidden) {
  @apply hidden lg:block;
}

.root :global(.pagebuilder-mobile-only) {
  @apply lg:hidden;
}
