.slider :global(.slick-slide) {
  @apply lg:max-w-[272px];

  &:first-child {
    @apply ml-auto;
  }
  &:last-child {
    @apply mr-auto;
  }
}

.slider :global(.slick-track) {
  @apply flex;
}
